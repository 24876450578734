import { applyCSVProtection } from 'Common.functions';
import Swal from 'sweetalert2';

/**
 * Used to process data from Team's Export and Candidates > Print Materials' Export
 * @param {Object} options
 * @param {Array} options.items
 * @param {String} options.type
 * @returns {Array} newCSVData
 */
export const processExportCandidates = (options) => {
  const { items, type } = options;

  const newCSVData = [];

  // TODO: Add Interiew DAte for Evaluation types
  //   const headers = [
  //     'Evaluator',
  //     'Candidate Last Name',
  //     'Candidate First Name',
  //     'Type',
  //     'Interview Date',
  //     'Session Label',
  //     'DNR',
  //     'Medical School',
  //     'Last Residency',
  //     'Email',
  //     'AAMCID',
  //     'SFMatchID',
  //     'NRMPID',
  //     'Cell Phone',
  //     'Preferred Phone',
  //   ];

  //   const headerDictionary = [
  //     {
  //       label: 'Evaluator',
  //       key: 'Evaluator',
  //     },
  //     {
  //       label: 'Candidate Last Name',
  //       key: 'LastName',
  //     },
  //     { label: 'Candidate First Name', key: 'FirstName' },
  //     { label: 'Type', key: 'Type' },
  //     { label: 'Interview Date', key: 'DateOfInterview' },
  //     { label: 'Session Label', key: 'SessionLabel' },
  //     { label: 'DNR', key: 'EnableDoNotRank' },
  //     { label: 'Medical School', key: 'MedicalSchoolOfGraduation' },
  //     { label: 'Last Residency', key: 'LastResidency' },
  //     { label: 'Email', key: 'Email' },
  //     { label: 'AAMCID', key: 'AAMCID' },
  //     { label: 'SFMatchID', key: 'SFMatchID' },
  //     { label: 'NRMPID', key: 'NRMPID' },
  //     { label: 'Cell Phone', key: 'CellPhone' },
  //     { label: 'Preferred Phone', key: 'PreferredPhone' },
  //   ];

  // Non-answers, Non-additional scores.
  const headerDictionary = {
    Evaluator: 'Evaluator',
    'Candidate Last Name': 'LastName',
    'Candidate First Name': 'FirstName',
    Type: 'Type',
    'Interview Date': 'DateOfInterview',
    'Session Label': 'SessionLabel',
    DNR: 'EnableDoNotRank',
    'Medical School': 'MedicalSchoolOfGraduation',
    'Last Residency': 'LastResidency',
    Email: 'Email',
    AAMCID: 'AAMCID',
    SFMatchID: 'SFMatchID',
    NRMPID: 'NRMPID',
    'Cell Phone': 'CellPhone',
    'Preferred Phone': 'PreferredPhone',
    'Program Signal': 'ProgramSignal',
  };

  const headers = Object.keys(headerDictionary);

  if (items.length <= 0) {
    Swal.fire('Oops', 'There are no candidates to export!', 'error');
    return;
  }

  const additionalScoreHeaders = [];

  // Make headers
  items.forEach((c) => {
    // shouldv'e been USers/Evaluators, but we need to move forwrd. Is a typo off of API.
    // Make HEaders row. Add each Question at the end of the row.
    if (c.Candidates) {
      c.Candidates.forEach((candidate) => {
        const { EvaluationAnswers = [], AdditionalScores = [], AnswerNumber, AnswerText } = candidate;

        // GET evaluation questions and put in header.
        if (EvaluationAnswers) {
          EvaluationAnswers.forEach((answer, i) => {
            const { Answer, QuestionText, QuestionType } = answer;

            if (QuestionText && headers.indexOf(QuestionText) < 0) {
              headers.push(QuestionText);
            } else if (!QuestionText) {
              const unnamedExists = headers.indexOf(`Unnamed ${i + 1}`) >= 0;
              //   console.log('unnamed answers: ', AnswerNumber, ' / ', AnswerText);
              if (!unnamedExists) {
                headers.push(`Unnamed ${i + 1}`);
              }
            }
          });
        }

        if (AdditionalScores) {
          AdditionalScores.forEach((score, i) => {
            const { ScoreType } = score;
            const scoreTypeExists = additionalScoreHeaders.indexOf(`${ScoreType}`) >= 0;

            if (ScoreType && !scoreTypeExists) {
              additionalScoreHeaders.push(`${ScoreType}`);
            }
          });
        }
      });
    }
  });

  additionalScoreHeaders.forEach((header) => {
    const headerExists = headers.indexOf(header) >= 0;

    if (!headerExists) {
      headers.push(header);
    }
  });

  if (type === 'evaluation') {
    headers.push('Final Score');
  } else {
    headers.push('Prescreen Score');
  }

  // Make template, to intiialize each column. We can then just plug each candidate proeprty into their appropriate column.
  const dataRowTemplate = [];
  headers.forEach((h) => {
    dataRowTemplate.push('');
  });

  newCSVData.push(headers);
  // Populate body
  items.forEach((c) => {
    // shouldv'e been USers/Evaluators, but we need to move forwrd. Is a typo off of API.
    if (c.Candidates) {
      const { UserLast, UserFirst } = c;
      const evaluatorName = `${UserLast}${UserLast && UserFirst ? ', ' : ''}${UserFirst}`;

      c.Candidates.forEach((candidate) => {
        const { EvaluationAnswers = [], AdditionalScores = [], FinalScore = 0, PrescreenScore = 0 } = candidate;
        const newCSVDataRow = [];

        headers.forEach((key, i) => {
          const dicKey = headerDictionary[key];
          const headerIndex = headers.indexOf(key);
          // dictionary only has non-answers and non-additional scores.
          // We want answers and scores to be at the end.
          if (dicKey) {
            if (key === 'DNR') {
              const value = candidate[dicKey] ? '1' : '';
              newCSVDataRow[i] = value;
              return;
            }

            if (key === 'Evaluator') {
              newCSVDataRow[i] = evaluatorName;
              return;
            }

            if (key === 'Type') {
              newCSVDataRow[i] = type;
              return;
            }
            const value = candidate[dicKey] || '';
            newCSVDataRow[i] = value;
          }
        });

        // const newCSVDataRow = clone(dataRowTemplate);
        // newCSVDataRow[headers.indexOf('Candidate Last Name')] = LastName;
        // newCSVDataRow[headers.indexOf('Candidate First Name')] = FirstName;
        // newCSVDataRow[headers.indexOf('Type')] = type;
        // newCSVDataRow[headers.indexOf('Evaluator')] = evaluatorName;
        // newCSVDataRow[headers.indexOf('Interview Date')] = DateOfInterview;
        // newCSVDataRow[headers.indexOf('Medical School')] = MedicalSchoolOfGraduation;
        // newCSVDataRow[headers.indexOf('Last Residency')] = LastResidency;
        // newCSVDataRow[headers.indexOf('Session Label')] = SessionLabel;
        // newCSVDataRow[headers.indexOf('DNR')] = EnableDoNotRank ? '1' : '';

        // let headerIndex = 9;
        // while (headerIndex < headers.length) {
        //   const headerString = headers[headerIndex].replace(/\s/g, '');
        //   newCSVDataRow[headerIndex] = candidate[headerString] || '';
        //   headerIndex++;
        // }

        if (EvaluationAnswers) {
          EvaluationAnswers.forEach((answer, i) => {
            const { AnswerText = '', AnswerNumber, QuestionText, QuestionType } = answer;

            if (!QuestionText) {
              newCSVDataRow[headers.indexOf(`Unnamed ${i + 1}`)] = AnswerText;
            } else {
              newCSVDataRow[headers.indexOf(QuestionText)] =
                QuestionType === 'text' ? applyCSVProtection(AnswerText || '') : AnswerNumber;
            }
          });
        }

        if (AdditionalScores) {
          AdditionalScores.forEach((score, i) => {
            const { ScoreType, calculatedScore } = score;
            if (ScoreType && headers.indexOf(`${ScoreType}`) >= 0) {
              newCSVDataRow[headers.indexOf(`${ScoreType}`)] = calculatedScore;
            } else {
              // console.log('cant find ', ScoreType);
            }
          });
        }

        if (type === 'evaluation') {
          newCSVDataRow[headers.indexOf('Final Score')] = FinalScore;
        } else {
          newCSVDataRow[headers.indexOf('Prescreen Score')] = PrescreenScore;
        }

        newCSVData.push(newCSVDataRow);
      });
    }
  });

  return newCSVData;
};
